import toast from "react-hot-toast";
import emailjs from "@emailjs/browser";
import { useLockBodyScroll } from "@custom-react-hooks/use-lock-body-scroll";

import { CloseIcon, StripeIcon } from "../assets/Icons";
import { useState } from "react";

export const MainForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");

  const validateForm = () => {
    let newErrors = {};

    if (!name) newErrors.name = "Name is required.";
    if (!email) newErrors.email = "Email is required.";
    if (!subject) newErrors.subject = "Include a subject.";
    if (!message) newErrors.message = "Message cannot be empty.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = () => {
    const serviceId = process.env.REACT_APP_emailjs_serviceId;
    const publicKey = process.env.REACT_APP_emailjs_publicKey;
    const templateId = process.env.REACT_APP_emailjs_contact_templateId;

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      subject: subject,
      message: message,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        toast.dismiss();
        toast.success("Message sent!");
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
      })
      .catch((error) => {
        toast.error(`Error sending message: ${error}`);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      toast.loading("Sending...");
      sendEmail();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-3">
      <label className="contact-label">
        Your Name
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="contact-input"
        />
        {errors.name && <p className="text-red-600 text-xs font-medium">{errors.name}</p>}
      </label>
      <label className="contact-label">
        Email Address
        <input
          type="email"
          placeholder="Enter a valid email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="contact-input"
        />
        {errors.email && <p className="text-red-600 text-xs font-medium">{errors.email}</p>}
      </label>
      <label className="contact-label">
        Subject
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="contact-input"
        />
        {errors.subject && <p className="text-red-600 text-xs font-medium">{errors.subject}</p>}
      </label>
      <label className="contact-label">
        Message
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="6"
          className="contact-input"
        />
        {errors.message && <p className="text-red-600 text-xs font-medium">{errors.message}</p>}
      </label>
      <button type="submit" className="pry-cta-button w-full py-5">
        Send Message
      </button>
    </form>
  );
};

export const ContactForm = ({ value, setter }) => {
  useLockBodyScroll(value);

  return (
    <div className="fixed z-50 top-0 bottom-0 right-0 left-0">
      <div className="bg-white z-20 w-[90%] max-w-lg mx-auto mt-[8%] p-6 text-gray-900 rounded-md sm:p-8">
        <button onClick={() => setter(!value)} className="float-end">
          <CloseIcon />
        </button>
        <div className="text-center mt-8">
          <h2 className="text-2xl mb-3 text-red-900x font-bold">Get in Touch</h2>
          <p className="mb-8 px-4 text-sm leading-4 md:mb-10">
            Leave a message, and a representative will in touch with you.
          </p>
        </div>
        <MainForm />
      </div>
      <div
        onClick={() => setter(!value)}
        className="absolute -z-10 left-0 right-0 -top-10 bottom-0 bg-black bg-opacity-50"
      />
    </div>
  );
};

export const DonationForm = ({ value, setter }) => {
  useLockBodyScroll(value);

  return (
    <div className="fixed z-50 top-0 bottom-0 right-0 left-0">
      <div className="bg-white z-20 w-[90%] max-w-lg mx-auto mt-[25vh] p-6 py-12 text-gray-900 rounded-lg shadow-md sm:p-8">
        <button onClick={() => setter(!value)} className="float-end -mt-9 -mr-4 sm:-mt-4">
          <CloseIcon />
        </button>
        <div className="text-center mt-4 mb-10">
          <h2 className="text-2xl mb-3 font-bold">Support Our Mission</h2>
          <p className="text-sm leading-4">
            Every donation transforms lives. Choose your preferred payment platform and join us in
            making a lasting impact.
          </p>
        </div>

        <div className="flex justify-en w-full gap-6">
          {[
            {
              icon: require("../assets/images/stripe-icon.png"),
              link: "https://donate.stripe.com/4gwcPQ3ae41588M000",
            },
            {
              icon: require("../assets/images/paypal-icon.png"),
              link: "https://www.paypal.com/donate/?hosted_button_id=A65CLSWZMN5K4",
            },
            {
              icon: require("../assets/images/square-icon.png"),
              link: "https://square.link/u/ZUO5bv2w",
            },
            { icon: require("../assets/images/paystack-icon.png"), link: "rbg" },
          ].map(({ icon, link }) => (
            <a className="" key={link} href={link}>
              <img className="w-[80%]x" src={icon} alt="link to payment" />
            </a>
          ))}
        </div>
        <hr className="bg-gray-200 mt-6 w-full h-[1px]" />
      </div>
      <div
        onClick={() => setter(!value)}
        className="absolute -z-10 left-0 right-0 -top-10 bottom-0 bg-black bg-opacity-60"
      />
    </div>
  );
};

export const VolunteerForm = ({ value, setter }) => {
  return (
    <div className="fixed z-50 top-0 bottom-0 right-0 left-0">
      <div
        onClick={() => setter(!value)}
        className="absolute -z-10 left-0 right-0 -top-10 bottom-0 bg-black bg-opacity-50"
      />
      <form
        action=""
        className="bg-white z-20 w-[90%] max-w-lg mx-auto mt-[8%] p-6 space-y-4 text-[#333] rounded-md sm:p-8"
      >
        <button onClick={() => setter(!value)} className="float-end">
          <CloseIcon />
        </button>
        <p className="text-lg font-bold">Get in Touch</p>

        <label htmlFor="" className="contact-label">
          Your Name <input type="text" name="" id="" className="contact-input" />
        </label>
        <label htmlFor="" className="contact-label">
          Email Address <input type="email" name="" id="" className="contact-input" />
        </label>
        <label htmlFor="" className="contact-label">
          Subject <input type="text" name="" id="" className="contact-input" />
        </label>
        <label htmlFor="" className="contact-label">
          Message
          <textarea name="" id="" cols="30" rows="8" className="contact-input"></textarea>
        </label>
        <button type="submit" className="pry-cta-button w-full">
          Send
        </button>
      </form>
    </div>
  );
};

export const TestimonialCard = ({ imageURL, quote, name, address }) => {
  return (
    <div>
      <div className="relative bg-red-900 text-[#e9e5e5] h-[20rem] text-left py-8 p-6 mb-4 rounded-3xl shadow-md">
        <div className="flex justify-center items-center h-12 w-12 bg-[#e9e5e5] rounded-full mb-4 shadow-md">
          <svg className="h-4" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.625 41.5C9.48752 41.5004 6.47552 40.2678 4.23851 38.0678C2.0015 35.8679 0.718689 32.8769 0.666698 29.7398C0.359196 26.7844 0.40361 22.4248 2.40236 17.392C4.44553 12.2465 8.42936 6.63633 15.7 1.18333C16.059 0.914124 16.4674 0.718246 16.9021 0.606895C17.3367 0.495541 17.789 0.470882 18.2332 0.534336C19.1303 0.662487 19.9397 1.14174 20.4834 1.86666C21.0271 2.59159 21.2605 3.50281 21.1324 4.39986C21.0042 5.29691 20.525 6.1063 19.8 6.65C14.4564 10.6577 11.395 14.5527 9.64228 17.9592C11.2856 17.5372 12.9998 17.4697 14.6712 17.7611C16.3426 18.0526 17.9328 18.6964 19.3363 19.6498C20.7397 20.6031 21.9242 21.8442 22.8111 23.2905C23.698 24.7368 24.267 26.3554 24.4802 28.0385C24.6935 29.7217 24.5462 31.4309 24.048 33.0527C23.5499 34.6746 22.7123 36.1718 21.591 37.4451C20.4697 38.7183 19.0903 39.7384 17.5444 40.4375C15.9986 41.1366 14.3216 41.4988 12.625 41.5ZM43.375 41.5C40.2375 41.5004 37.2255 40.2678 34.9885 38.0678C32.7515 35.8679 31.4687 32.8769 31.4167 29.7398C31.1092 26.7844 31.1536 22.4248 33.1524 17.392C35.1921 12.2465 39.1794 6.63633 46.45 1.18333C46.809 0.914124 47.2174 0.718246 47.6521 0.606895C48.0867 0.495541 48.5391 0.470882 48.9832 0.534336C49.8803 0.662487 50.6897 1.14174 51.2334 1.86666C51.7771 2.59159 52.0105 3.50281 51.8824 4.39986C51.7542 5.29691 51.275 6.1063 50.55 6.65C45.2064 10.6577 42.145 14.5527 40.3923 17.9592C42.0356 17.5372 43.7498 17.4697 45.4212 17.7611C47.0926 18.0526 48.6828 18.6964 50.0863 19.6498C51.4897 20.6031 52.6742 21.8442 53.5611 23.2905C54.448 24.7368 55.017 26.3554 55.2302 28.0385C55.4435 29.7217 55.2962 31.4309 54.798 33.0527C54.2999 34.6746 53.4623 36.1718 52.341 37.4451C51.2197 38.7183 49.8403 39.7384 48.2944 40.4375C46.7486 41.1366 45.0716 41.4988 43.375 41.5Z"
              fill="#7f1d1d"
            />
          </svg>
        </div>

        <p className="text-lg italic text-center leading-normal">{quote}</p>
      </div>

      <div className="flex items-center gap-4 px-4 pb-4">
        <img
          src={imageURL}
          alt={name}
          className="h-[60px] w-[60px] rounded-full shadow-sm object-cover md:w-[80px] md:h-[80px]"
        />
        <p className="text-sm text-left">
          <span className="font-bold text-lg -mb-1 block">{name}</span>
          {address}
        </p>
      </div>
    </div>
  );
};
